import styled from "styled-components";

const ClientInfoChat = ({
  username,
  telegram_id,
  phone,
  id,
  tags,
  orders_count,
  orders_total_amount,
  orders_avg_amount,
}) => {
  return (
    <Wrapper>
      <Info>
        <p>{`${username ?? "Без имени"}/${telegram_id ?? "Без ID"}/${
          phone ?? "Без номера"
        }`}</p>
        <p>Клиент:</p>
        <p>id_{id}</p>
        <div>
          {tags?.map((item) => (
            <TagItem key={item.hash}>{item.title}</TagItem>
          ))}
        </div>
      </Info>
      <OrdersInfo>
        <OrdersText>
          <p>Количество сделок</p>
          <p>Общая сумма</p>
          <p>Средний чек</p>
        </OrdersText>
        <div>
          <p>{orders_count ?? 0}</p>
          <p>{orders_total_amount ?? 0} бат</p>
          <p>{orders_avg_amount ?? 0} бат</p>
        </div>
      </OrdersInfo>
      {/* <Button>ЗАБРАТЬ КЛИЕНТА</Button> */}
    </Wrapper>
  );
};

export default ClientInfoChat;

// const Wrapper = styled.div`
//   padding: 20px;
// `;

const OrdersText = styled.div`
  border-right: 1px solid #000;
  width: 70%;
`;

const Wrapper = styled.div`
  padding: 12px 14px;
  display: flex;
`;
const TagItem = styled.div`
  color: #000;
  background: #aeaeae;
  border-radius: 8px;
  margin-right: 20px;
  padding: 2px 8px;
`;

const Info = styled.div`
  width: 40%;
  p {
    margin-bottom: 5px;
  }
  > div {
    display: flex;
  }
`;
const OrdersInfo = styled.div`
  border-radius: 12px;
  background: #aeaeae;
  display: flex;
  min-width: 30%;
  > div {
    width: 50%;
    padding: 10px;
    p {
      text-wrap: nowrap;
      height: 18px;
    }
  }
`;
