import styled from "styled-components";
import { postSetMenager } from "../../hooks/usePostSetMenager";

const OrderItem = ({
  tags,
  city,
  comment,
  client_currrency,
  target_currrency,
  supply_method,
  client_get,
  client_pay,
  client,
  money_received_at,
  id,
  checkClients,
  setCheckClients,
}) => {
  const checkEnam = (value) => {
    switch (value) {
      case "atm":
        return "АТМ";
      case "invoice":
        return "СЧЁТ";
      case "courier":
        return "Курьер";
      case "office":
        return "ОФИС";
      default:
        return "";
    }
  };

  const sendData = () => {
    postSetMenager({ id: client.id, checkClients, setCheckClients });
  };

  return (
    <Wrapper>
      <Info>
        <p>{`${client?.username ?? "Без имени"}/${
          client?.telegram_id ?? "Без ID"
        }/${client?.phone ?? "Без номера"}`}</p>
        <p>Заявка:</p>
        <p>Порядковый номер/{client?.id}</p>
        <div>
          {tags?.map((item) => (
            <TagItem key={item.hash}>{item.title}</TagItem>
          ))}
        </div>
      </Info>
      <OrdersInfo>
        <OrdersText>
          <p>
            {client_currrency} - {client_pay}
          </p>
          <p>
            К выдаче: {client_get} {target_currrency}
          </p>
          <p>{checkEnam(supply_method)}</p>
        </OrdersText>
        <div>
          <p>{city}</p>
          <p>{comment}</p>
        </div>
      </OrdersInfo>
      <ButtonsWrapper>
        {money_received_at ? (
          <SecondBtn color={"#00BB13"}>Оплачено</SecondBtn>
        ) : (
          <SecondBtn>Не оплачено</SecondBtn>
        )}
        <Button onClick={() => sendData()}>ЗАБРАТЬ КЛИЕНТА</Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

const SecondBtn = styled.div`
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  text-wrap: nowrap;
  width: 100%;
  background: ${(props) => props.color ?? "#FF8585"};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export default OrderItem;

const OrdersText = styled.div`
  // border-right: 1px solid #000;
  width: 70%;
`;

const Wrapper = styled.div`
  padding: 14px;
  display: flex;
`;
const TagItem = styled.div`
  color: #000;
  background: #aeaeae;
  border-radius: 8px;
  margin-right: 20px;
  padding: 2px 8px;
`;

const Info = styled.div`
  width: 40%;
  p {
    margin-bottom: 5px;
  }
  > div {
    display: flex;
  }
`;
const OrdersInfo = styled.div`
  display: flex;
  min-width: 30%;
  > div {
    width: 50%;
    padding: 10px;
    p {
      text-wrap: nowrap;
    }
  }
`;
const Button = styled.div`
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: #85bdff;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;
