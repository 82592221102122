import styled from "styled-components";

const MessComponent = ({ right, text, author, date }) => {
  function formatDate(isoDate) {
    const date = new Date(isoDate);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }
  return (
    <Wrapper right={right} text={!!text?.length}>
      <Author>{author}</Author>
      <DateB>{formatDate(date)}</DateB>
      <Mess>{text}</Mess>
    </Wrapper>
  );
};

export default MessComponent;

const DateB = styled.div`
  font-size: 13px;
  margin-top: 2px;
`;
const Mess = styled.div`
  word-wrap: break-word;
  word-break: break-all;
  margin-top: 10px;
`;

const Wrapper = styled.div`
  padding: 14px;
  display: flex;
  background: #fff;
  border-radius: 10px;
  width: fit-content;
  max-width: 70%;
  margin-top: 8px;
  margin-left: ${(props) => (props.right ? "auto" : "0px")};
  display: flex;
  flex-direction: column;
  gap: 4px;
  display: ${(props) => (props.text ? "block" : "none;")};
`;

const Author = styled.div`
  font-weight: 600;
`;
