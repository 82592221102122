import axios from "axios";
import { baseUrl } from "../config/config";

export const postMessage = ({
  text,
  id,
  setMessage,
  setCheckNewMess,
  checkNewMess,
}) => {
  axios
    .post(
      `${baseUrl}/${"service-chats"}/${id}/messages`,
      { text: text },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then(() => {
      setMessage("");
      setCheckNewMess(checkNewMess + 1);
    })
    .catch((error) => {
      console.error("Произошла ошибка:", error);
    });
};
