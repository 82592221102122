import { useState } from "react";
import styled from "styled-components";
import CreacteOrders from "./CreacteOrders";

const CalcComponent = () => {
  const [clientGives, setClientGives] = useState();
  const [clientRecevies, setClientRecevies] = useState();
  const [coursesUSDT, setCoursesUSDT] = useState();
  const [coursesRUB, setCoursesRUB] = useState();
  const [persentForUser, setPersentForUser] = useState();
  return (
    <Wrapper>
      <Title>Калькулятор</Title>
      <InputWrapper>
        <Label>Клиент отдаёт</Label>
        <Input
          type="number"
          value={clientGives}
          onChange={(e) => setClientGives(e.target.value)}
        ></Input>
      </InputWrapper>

      <InputWrapper>
        <Label>Клиент получает</Label>
        <Input
          type="number"
          value={clientRecevies}
          onChange={(e) => setClientRecevies(e.target.value)}
        ></Input>
      </InputWrapper>

      <InputWrapper>
        <Label>{"Курс USDT > THB"}</Label>
        <Input
          type="number"
          value={coursesUSDT}
          onChange={(e) => setCoursesUSDT(e.target.value)}
        ></Input>
      </InputWrapper>

      <InputWrapper>
        <Label>{"Курс RUB  > USDT"}</Label>
        <Input
          type="number"
          value={coursesRUB}
          onChange={(e) => setCoursesRUB(e.target.value)}
        ></Input>
      </InputWrapper>

      <InputWrapper>
        <Label>Какой % клиенту</Label>
        <Input
          type="number"
          value={persentForUser}
          onChange={(e) => setPersentForUser(e.target.value)}
        ></Input>
      </InputWrapper>
      <Button>Рассчитать курс</Button>

      <Line />
      <CreacteOrders />
    </Wrapper>
  );
};

export default CalcComponent;

const Line = styled.div`
  height: 2px;
  border: none;
  background: #000;
  margin: 20px 0;
  width: 100%;
`;

const Button = styled.div`
  width: 100%;
  margin: 20px auto;
  text-align: center;
  padding: 10px 0;
  background: #ffcece;
  border-radius: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`;
const Label = styled.div`
  text-wrap: nowrap;
  margin-right: 8px;
`;
const Input = styled.input`
  padding: 4px 10px;
  border: none;
  background: #dedede;
  border-radius: 12px;
  width: 40%;
`;
const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
`;
const Wrapper = styled.div`
  padding: 20px;
  background: #f2f2f2;
  margin-left: 20px;
  border-radius: 30px;
  margin-top: 12px;
`;
