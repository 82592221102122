import styled from "styled-components";
import MessComponent from "../messComponent/messComponent";
import { useEffect, useRef, useState } from "react";
import PageSpinner from "../pageSpinner/PageSpinner";
import { postMessage } from "../../hooks/usePostMessage";
import { useGetChatById } from "../../hooks/useGetChatById";

const ChatModal = ({ activeChat, setCheckNewMess, checkNewMess }) => {
  const { data: messages, isLoading } = useGetChatById(
    activeChat,
    checkNewMess
  );

  const [checkAutor, setCheckAutor] = useState();
  const messagesEndRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setCheckAutor(messages[0]?.author);
  }, [messages]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const [message, setMessage] = useState("");

  // const sendingMess = () => {
  //   if (!!message.length) {
  //     usePostMessage({ text: message, id: messages[0]?.id });
  //   }
  // };

  const sendData = () => {
    postMessage({
      text: message,
      id: activeChat,
      setMessage,
      setCheckNewMess,
      checkNewMess,
    });
  };

  // const handleClick = () => {

  // };

  return (
    <Wrapper ref={wrapperRef}>
      {isLoading ? (
        <>
          {messages?.map((item) => (
            <MessComponent
              key={item.id}
              right={checkAutor === item.author}
              author={item.author}
              date={item.datetime}
              text={item.text}
            />
          ))}
          <SendMessWrapper>
            <SenderMess
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </SendMessWrapper>
          <Test onClick={() => sendData()}>Отправить</Test>
          {/* <Test onClick={() => alert(1)}>Отправить</Test> */}
          <div ref={messagesEndRef} />
        </>
      ) : (
        <PageSpinner />
      )}
    </Wrapper>
  );
};

export default ChatModal;

const Wrapper = styled.div`
  padding: 14px 14px 0 14px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #e3e3e3;
  overflow-y: auto;
  scrollbar-width: none;
  border: 2px solid #000;
  border-radius: 18px;
  max-height: 80vh;
  position: relative;
  width: 80%;
  margin-top: 12px;
`;
const SendMessWrapper = styled.div`
  position: sticky;
  bottom: 0px;
  left: 13px;
  z-index: 1;
  padding-bottom: 8px;
  background: #e3e3e3;
  border-right: 1px solid #e3e3e3;
`;

const SenderMess = styled.textarea`
  padding: 13px 160px 13px 13px;
  margin-top: 10px;
  border-radius: 12px;
  border: none;
  min-height: 40px;
  width: calc(100% - 173px);
  position: relative;
`;

const Test = styled.div`
  position: fixed;
  right: calc(15% + 140px);
  bottom: 19.8%;
  background: #929292;
  z-index: 2;
  padding: 9px 20px 10px 20px;
  border-radius: 12px;
  color: #fff;
  &: hover {
    cursor: pointer;
  }
`;
