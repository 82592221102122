import { useState } from "react";
import styled from "styled-components";

const CreacteOrders = () => {
  const [gives, setGives] = useState();
  const [recevies, setRecevies] = useState();
  const [markUp, setMarkUp] = useState();

  const [netto, setNetto] = useState();

  const [select, setSelect] = useState("");
  const [showSelect, setShowSelect] = useState(false);
  const [createOrder, setCreateOrder] = useState(false);

  const [selectTag, setSelectTag] = useState("");
  const [showSelectTag, setShowSelectTag] = useState(false);

  const [selectReg, setSelectReg] = useState("");
  const [showSelectReg, setShowSelectReg] = useState(false);
  const officeArr = [
    { name: "Офис 1", id: 1 },
    { name: "Офис 2", id: 2 },
    { name: "Офис 3", id: 3 },
    { name: "Офис 4", id: 4 },
  ];

  const tegArr = [
    { name: "teg 1", id: 1 },
    { name: "teg 2", id: 2 },
    { name: "teg 3", id: 3 },
    { name: "teg 4", id: 4 },
  ];

  const regionArr = [
    { name: "region 1", id: 1 },
    { name: "region 2", id: 2 },
    { name: "region 3", id: 3 },
    { name: "region 4", id: 4 },
  ];

  const [haveMoney, setHaveMoney] = useState(false);
  return (
    <Wrapper>
      {haveMoney ? (
        <>123</>
      ) : (
        <>
          {createOrder ? (
            <>
              <Button color={"black"}>Отгрузить заявку</Button>

              <Button color={"black"}>Изменить заявку</Button>

              <Button color={"black"}>Принять оплату</Button>

              <Button color={"black"} onClick={() => setHaveMoney(true)}>
                Деньги приняты
              </Button>
            </>
          ) : (
            <>
              <Tages>
                <div>Счёт</div>
                <div>АТМ</div>
                <div>Курьер</div>
                <Select onClick={() => setShowSelect(!showSelect)}>
                  {!!select?.length ? select : "ОФИС"}
                </Select>
                {showSelect && (
                  <OptionsWrapper>
                    {officeArr.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          setSelect(item.name);
                          setShowSelect(false);
                        }}
                      >
                        {item.name}
                      </div>
                    ))}
                  </OptionsWrapper>
                )}
              </Tages>
              <InputWrapper>
                <Label>Отдаёте</Label>
                <Input
                  type="number"
                  value={gives}
                  onChange={(e) => setGives(e.target.value)}
                ></Input>
              </InputWrapper>

              <InputWrapper>
                <Label>Получаете</Label>
                <Input
                  type="number"
                  value={recevies}
                  onChange={(e) => setRecevies(e.target.value)}
                ></Input>
              </InputWrapper>

              <InputWrapper>
                <Label>Наценка</Label>
                <Input
                  type="number"
                  value={markUp}
                  onChange={(e) => setMarkUp(e.target.value)}
                ></Input>
              </InputWrapper>

              <InputWrapper>
                <Label>Итого к выдаче</Label>
                <Input
                  type="number"
                  value={netto}
                  onChange={(e) => setNetto(e.target.value)}
                ></Input>
              </InputWrapper>

              <SelectWrapper>
                <Select onClick={() => setShowSelectTag(!showSelectTag)}>
                  {!!selectTag?.length ? selectTag : "Добавить ТЕГ"}
                </Select>
                {showSelectTag && (
                  <OptionsWrapperR>
                    {tegArr.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          setSelectTag(item.name);
                          setShowSelectTag(false);
                        }}
                      >
                        {item.name}
                      </div>
                    ))}
                  </OptionsWrapperR>
                )}
              </SelectWrapper>

              <SelectWrapper>
                <Select onClick={() => setShowSelectReg(!showSelectReg)}>
                  {!!selectReg?.length ? selectReg : "Добавить регион"}
                </Select>
                {showSelectReg && (
                  <OptionsWrapperR>
                    {regionArr.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          setSelectReg(item.name);
                          setShowSelectReg(false);
                        }}
                      >
                        {item.name}
                      </div>
                    ))}
                  </OptionsWrapperR>
                )}
              </SelectWrapper>

              <Button onClick={() => setCreateOrder(true)}>
                Создать заявку
              </Button>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default CreacteOrders;

const SelectWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  z-index: 1;
  background: #f2f2f2;
  top: 30px;
  right: 0;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 0px 24px 5px #0b0c0e0f;

  > div {
    padding: 8px 14px;
    &:hover {
      cursor: pointer;
      background: #9d9d9d;
    }
  }
`;

const OptionsWrapperR = styled.div`
  position: absolute;
  z-index: 1;
  background: #f2f2f2;
  top: 20px;
  left: 50px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 0px 24px 5px #0b0c0e0f;

  > div {
    padding: 8px 14px;
    &:hover {
      cursor: pointer;
      background: #9d9d9d;
    }
  }
`;
const Select = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const Tages = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 15px;
`;

const Button = styled.div`
  width: 100%;
  margin: 20px auto;
  text-align: center;
  padding: 10px 0;
  background: ${(props) => (props.color === "black" ? "#929292" : "#ffcece")};
  border-radius: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const Label = styled.div`
  text-wrap: nowrap;
  margin-right: 8px;
`;
const Input = styled.input`
  padding: 4px 10px;
  border: none;
  background: #dedede;
  border-radius: 12px;
  width: 40%;
`;
const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
`;
const Wrapper = styled.div``;
