import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../config/config";

export const useGetOrders = (
  url = "users",
  search = "",
  checkNewMess = 1,
  checkClients = 1
) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    axios
      .get(`${baseUrl}/${url}${search}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const data = response.data;
        setData(data);
        setIsLoading(true);
      })
      .catch((error) => {
        console.error("Произошла ошибка:", error);
        setIsLoading(true);
      });
  }, [search, url, checkNewMess, checkClients]);
  return { data, isLoading };
};
