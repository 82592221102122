import axios from "axios";
import { baseUrl } from "../config/config";
export const postSetMenager = ({ id, checkClients, setCheckClients }) => {
  axios
    .post(`${baseUrl}/${"clients"}/${id}/set-manager/`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then(() => {
      setCheckClients(checkClients + 1);
    })
    .catch((error) => {
      console.error("Произошла ошибка:", error);
    });
};
