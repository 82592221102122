import styled from "styled-components";

const ChatPreview = ({ id, title, chat_type, setActiveChat, activeChat }) => {
  return (
    <Wrapper onClick={() => setActiveChat(id)} active={activeChat === id}>
      <div>{chat_type}</div>
      <div>{title}</div>
      <div>{id}</div>
    </Wrapper>
  );
};
export default ChatPreview;

const Wrapper = styled.div`
  padding: 14px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #aeaeae;
  transition: all 0.3s ease;
  background: ${(props) => (props.active ? "#aeaeae" : "#fff")};
  &:hover {
    background: #aeaeae;
    cursor: pointer;
  }
`;
