import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../config/config";

export const useGetChatById = (activeChat, checkNewMess = 1) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    axios
      .get(`${baseUrl}/service-chats/${activeChat}/messages`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const data = response.data;
        setData(data);
        setIsLoading(true);
      })
      .catch((error) => {
        console.error("Произошла ошибка:", error);
        setIsLoading(true);
      });
  }, [activeChat, checkNewMess]);
  return { data, isLoading };
};
