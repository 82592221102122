import {
  Chat,
  Channel,
  ChannelHeader,
  MessageList,
  MessageInput,
  ChannelList,
} from "stream-chat-react";
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";

const ChatPage = () => {
  const apiKey = "bwyqasxv2zcp";
  const userId = "vladislav9601";
  const userToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidmxhZGlzbGF2OTYwMSJ9.FkCXUQDhP5dcE6EYcFslqnnATcGHWILiIciAmofWsFY";
  const chatClient = StreamChat.getInstance(apiKey);

  chatClient.connectUser(
    {
      id: userId,
      name: "John Doe",
      image: "https://getstream.io/random_svg/?id=JohnDoe&name=John",
    },
    userToken
  );

  const channel = chatClient.channel(
    "messaging",
    "general",
    {
      name: "Test",
      image: "https://www.drupal.org/files/project-images/react.png",
    },
    "test",
    {
      name: "Test",
      image: "https://www.drupal.org/files/project-images/react.png",
    }
  );

  const filters = { members: { $in: ["John Doe", "Vladik"] } };
  // const sort = { last_message_at: -1 };
  // const options = { limit: 10 };

  const customOnMessageNew = async (setChannels, event) => {
    const eventChannel = event.channel;

    // If the channel isn't frozen, then don't add it to the list.
    if (!eventChannel?.id || !eventChannel.frozen) return;

    try {
      const newChannel = chatClient.channel(eventChannel.type, eventChannel.id);
      await newChannel.watch();
      setChannels((channels) => [newChannel, ...channels]);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Chat client={chatClient} theme="messaging light">
      <ChannelList filters={filters} onMessageNew={customOnMessageNew} />;
      <Channel channel={channel}>
        <ChannelHeader />
        <MessageList />
        <MessageInput />
      </Channel>
    </Chat>
  );
};

export default ChatPage;
