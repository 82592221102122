import { useState } from "react";
import styled from "styled-components";
import UserItem from "../components/userItem/UserItem";
import OrderItem from "../components/orderItem/OrderItem";
import { useGetOrders } from "../hooks/useGetOrders";
import ChatModal from "../components/chatModal/chatModal";
import ClientInfoChat from "../components/clientInfoChat/ClientInfoChat";
import CalcComponent from "../components/calcComponent/calcComponent";
import PageSpinner from "../components/pageSpinner/PageSpinner";
import { usePostJWT } from "../hooks/usePostJWT";
import AllChats from "../components/allChats/AllChats";

const MainPage = () => {
  const [searchValue, setSearchValue] = useState();
  const [typeBar, setTypeBar] = useState("clients");

  const [searchParam, setSearchParam] = useState("?has_been_answered=False");

  const [checkNewMess, setCheckNewMess] = useState(1);
  const [checkClients, setCheckClients] = useState(1);

  const { data: orders, isLoading } = useGetOrders(
    typeBar,
    searchParam,
    checkNewMess,
    checkClients
  );
  const { data: clearOrders } = useGetOrders("orders", "");
  const { data: clearClients } = useGetOrders("clients", "");
  const { data: countMy } = useGetOrders("clients", "?my=True");
  const { data: countIsBack } = useGetOrders("clients", "?has_orders=True");
  const { data: recevingCount } = useGetOrders(
    "service-chats",
    "?chat_type=RECEIVING"
  );

  const { data: cashCount } = useGetOrders("service-chats", "/");

  // const { data: cashCount } = useGetOrders("service-chats", "");

  // console.log("cashCount", cashCount);

  const { data: countPartners } = useGetOrders("clients", "?tags=partner");
  const { data: countNoTouch } = useGetOrders(
    "clients",
    "?has_orders=False&has_been_answered=True"
  );

  const { data: countVip } = useGetOrders("clients", "?tags=vip");

  const [activeBar, setActiveBar] = useState("Новые");

  // eslint-disable-next-line no-unused-vars
  const { data: JWT } = usePostJWT();

  // console.log("JWT", JWT);

  const valueBarFirst = [
    {
      name: "Новые",
      typeBar: "clients",
      searchParams: "?has_been_answered=False",
      count: clearClients?.filter((item) => item.is_new).length,
      id: 1,
    },
    {
      name: "Возвратные",
      typeBar: "clients",
      searchParams: "?has_orders=True",
      count: countIsBack.length,
      id: 2,
    },
    {
      name: "Не менялись",
      typeBar: "clients",
      searchParams: "?has_orders=False&has_been_answered=True",
      count: countNoTouch.length,
      id: 3,
    },
    {
      name: "VIP клиенты",
      typeBar: "clients",
      searchParams: "?tags=vip",
      count: countVip?.length,
      id: 4,
    },
    // {
    //   name: "Заявки на отгрузку",
    //   typeBar: "orders",
    //   searchParams: "?supply_method=atm&has_manager=True",
    //   count: 3,
    //   id: 5,
    // },
  ];
  const valueBarOrders = [
    {
      name: "Счёт",
      typeBar: "orders",
      searchParams: "?supply_method=invoice&has_manager=True&is_closed=False",
      count: clearOrders?.filter(
        (item) =>
          item.supply_method === "invoice" &&
          !item.closed_at &&
          item.manager_connected_at
      ).length,
      id: 1,
    },
    {
      name: "АТМ",
      typeBar: "orders",
      searchParams: "?supply_method=atm&has_manager=True&is_closed=False",
      count: clearOrders?.filter(
        (item) =>
          item.supply_method === "atm" &&
          !item.closed_at &&
          item.manager_connected_at
      ).length,
      id: 2,
    },
    {
      name: "Курьер",
      typeBar: "orders",
      searchParams: "?supply_method=courier&has_manager=True&is_closed=False",
      count: clearOrders?.filter(
        (item) =>
          item.supply_method === "courier" &&
          !item.closed_at &&
          item.manager_connected_at
      ).length,
      id: 3,
    },
    {
      name: "Офис",
      typeBar: "orders",
      searchParams: "?supply_method=office&has_manager=True&is_closed=False",
      count: clearOrders?.filter(
        (item) =>
          item.supply_method === "office" &&
          !item.closed_at &&
          item.manager_connected_at
      ).length,
      id: 4,
    },
    {
      name: "Завершённые заявки",
      typeBar: "orders",
      searchParams: "?has_manager=True&is_closed=True",
      count: clearOrders?.filter((item) => !!item.closed_at).length,
      id: 5,
    },
  ];
  const valueBarSecond = [
    {
      name: "Партнёры",
      typeBar: "clients",
      searchParams: "?tags=partner",
      count: countPartners?.length,
      id: 1,
    },
    {
      name: "Кассы",
      typeBar: "service-chats",
      // searchParams: "/-4105544050/messages",
      searchParams: "/",
      count: cashCount?.length,
      id: 2,
    },
    {
      name: "Приёмки",
      typeBar: "service-chats",
      searchParams: "?chat_type=RECEIVING",
      count: recevingCount?.length,
      id: 3,
    },
    {
      name: "Все клиенты",
      typeBar: "clients",
      searchParams: "",
      count: clearClients?.length,
      id: 4,
    },
    {
      name: "Мои заявки",
      typeBar: "clients",
      searchParams: "?my=True",
      count: countMy.length,
      id: 5,
    },
    {
      name: "Мои расходы",
      typeBar: "clients",
      searchParams: "?has_orders=False",
      id: 6,
    },
    {
      name: "ОТЧЁТ",
      typeBar: "clients",
      searchParams: "?has_orders=False",
      id: 7,
    },
  ];

  const [activeChat, setActiveChat] = useState();

  return (
    <>
      {isLoading ? (
        <Wrapper>
          <MenuBar>
            <WrapperInput>
              <SearchInput
                value={searchValue}
                placeholder="Поиск"
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </WrapperInput>
            {valueBarFirst.map((item) => (
              <MainOptions
                key={item.id}
                active={activeBar === item.name}
                onClick={() => {
                  setActiveBar(item.name);
                  setTypeBar(item.typeBar);
                  setSearchParam(item.searchParams);
                }}
              >
                <p>{item.name}</p>
                {!!item.count && <span>{item.count}</span>}
              </MainOptions>
            ))}
            <Title>Заявки на отгрузку</Title>
            {valueBarOrders.map((item) => (
              <SecondOptions
                key={item.id}
                active={activeBar === item.name}
                onClick={() => {
                  setActiveBar(item.name);
                  setTypeBar(item.typeBar);
                  setSearchParam(item.searchParams);
                }}
              >
                <p>{item.name}</p>
                {!!item.count && <span>{item.count}</span>}
              </SecondOptions>
            ))}
            {valueBarSecond.map((item) => (
              <MainOptions
                key={item.id}
                active={activeBar === item.name}
                onClick={() => {
                  setActiveBar(item.name);
                  setTypeBar(item.typeBar);
                  setSearchParam(item.searchParams);
                }}
              >
                <p>{item.name}</p>
                {!!item.count && <span>{item.count}</span>}
              </MainOptions>
            ))}
          </MenuBar>
          {typeBar !== "service-chats" && (
            <ClientsOrder>
              {typeBar === "clients" ? (
                <>
                  {orders.map((item) => (
                    <UserItem key={item.id} {...item} />
                  ))}
                </>
              ) : (
                <>
                  {orders.map((item) => (
                    <OrderItem
                      key={item.id}
                      {...item}
                      checkClients={checkClients}
                      setCheckClients={setCheckClients}
                    />
                  ))}
                </>
              )}
            </ClientsOrder>
          )}
          {typeBar === "service-chats" && (
            <ChatWrapper>
              <>
                {/* <ClientInfoChat /> */}

                <CalcWrapper>
                  <AllChats
                    orders={orders}
                    setActiveChat={setActiveChat}
                    activeChat={activeChat}
                  />

                  <ChatModal
                    activeChat={activeChat}
                    setCheckNewMess={setCheckNewMess}
                    checkNewMess={checkNewMess}
                  />

                  <CalcComponent />
                </CalcWrapper>
              </>
            </ChatWrapper>
          )}
        </Wrapper>
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

const NoRes = styled.div`
  padding: 40px;
  text-align: center;
  font-size: 34px;
  font-weight: 600;
  width: calc(100% - 80px);
`;

const CalcWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChatWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  flex-direction: column;
`;

export default MainPage;

const Title = styled.div`
  padding: 14px 0 3px 14px;
  border-top: 1px solid #000;
`;

const SearchInput = styled.input`
  border: none;
  border-radius: 8px;
  padding: 4px 8px;
`;
const MainOptions = styled.div`
  position: relative;
  padding: 15px 14px;
  background: ${(props) => (props.active ? "#aeaeae" : "#fff")};
  border-top: 1px solid #000;
  transition: all 0.3s ease;
  span {
    position: absolute;
    right: 10px;
    border-radius: 50%;
    background: red;
    top: 12px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    cursor: pointer;
    background: #aeaeae;
  }
`;
const WrapperInput = styled.div`
  background: #aeaeae;
  padding: 16px 8px;
`;
const SecondOptions = styled.div`
  position: relative;
  padding: 9px 0 9px 24px;
  transition: all 0.3s ease;
  background: ${(props) => (props.active ? "#aeaeae" : "#fff")};
  span {
    position: absolute;
    right: 10px;
    border-radius: 50%;
    background: red;
    top: 6px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    cursor: pointer;
    background: #aeaeae;
  }
`;

const Wrapper = styled.div`
  display: flex;
`;
const MenuBar = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-bottom-right-radius: 16px;
  overflow: hidden;
  width: 220px;
  height: fit-content;
`;
const ClientsOrder = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #000;
  border-radius: 18px;
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  height: calc(761.76px - 30px);
  overflow-y: scroll;
  scrollbar-width: none;
  > div {
    border-bottom: 1px solid #000;
    &:last-child {
      border: none;
    }
  }
`;
