import styled from "styled-components";
import ChatPreview from "../chatPreview/ChatPreview";

const AllChats = ({ orders, setActiveChat, activeChat }) => {
  return (
    <Wrapper>
      {orders?.map((item) => (
        <ChatPreview
          key={item.id}
          {...item}
          setActiveChat={setActiveChat}
          activeChat={activeChat}
        />
      ))}
    </Wrapper>
  );
};
export default AllChats;

const Wrapper = styled.div`
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
