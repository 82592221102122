import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../config/config";

export const usePostJWT = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    axios
      .post(
        `${baseUrl}/auth/jwt/create`,
        {
          password: "whK4TwSfZ4AuZDN",
          username: "root",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setData(data);
        setIsLoading(false);
        localStorage.setItem("token", data?.access);
      })
      .catch((error) => {
        console.error("Произошла ошибка:", error);
        setIsLoading(false);
      });
  }, []);
  return { data, isLoading };
};
